#logo{
    position: absolute;
    width: 4em;
    height: 4em;
    top: .9em;
    left: .5em;
  }
  
  .bolt{
    position: absolute;
    background-image: url('./../../images/bolt.svg');
    width: 100%;
    height: 100%;
    background-size: fit;
    background-position: center;
    background-repeat: no-repeat;
    outline: none;
  }
  
  .ma{
    position: absolute;
    background-image: url('./../../images/ma.svg');
    width: 100%;
    height: 100%;
    background-size: fit;
    background-position: center;
    background-repeat: no-repeat;
    outline: none;
  }
  