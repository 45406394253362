@import url(https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,400;0,600;0,900;1,300;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: left;
}

body {
  background-color: white;
}

main {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
}


.container {
  max-height: 100vh;
  overflow-y: scroll;
}

.panel h1 {
  position: absolute;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-size: 15vw;
  line-height: 12vw;
  /* white-space: nowrap; */
}

.panel {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.galleryContainer {
  position: absolute;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
}

.galleryImage {
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Old versions of Firefox */
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
#root, html, body{
    width: 100%;
    height: 100%;
    background-color: #333333;
}

/* main {
    display: block;
    position: relative;
    max-width: 1024px;
    min-width: 680px;
    background-color: white;
    padding: 30px 100px;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    box-sizing: border-box;
} */
.cv-title{
    font-size: 50px;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
}

.cv-subtitle{
    margin-top: 45px;
    font-size: 24px;
    font-family: 'Consolas', sans-serif;
    font-weight: 300;
    color:#2EA2C7;
    background-color: #282827;
    display: inline-block;
    padding: 5px 10px;
    box-sizing: border-box;
}
.cv-line{
    border:none;
    border-top: 2px dotted gray;
    background-color: none;
    margin-top: -2px;
    margin-left: -25px;
    margin-right: -25px;

}



.cv-name{
    font-size: 36px;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    margin-top: -10px;
}
#logo{
    position: absolute;
    width: 4em;
    height: 4em;
    top: .9em;
    left: .5em;
  }
  
  .bolt{
    position: absolute;
    background-image: url(../../static/media/bolt.78a327f6.svg);
    width: 100%;
    height: 100%;
    background-size: fit;
    background-position: center;
    background-repeat: no-repeat;
    outline: none;
  }
  
  .ma{
    position: absolute;
    background-image: url(../../static/media/ma.d4cfdb3f.svg);
    width: 100%;
    height: 100%;
    background-size: fit;
    background-position: center;
    background-repeat: no-repeat;
    outline: none;
  }
  
#loader{
    position: relative;
    align-self: start;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 15vw;
    height: 15vw;
  }

  .blub1{
    position: absolute;
    background-image: url(../../static/media/blub1.0c278582.svg);
    width: 100%;
    height: 100%;
    background-size: fit;  
    background-position: center;
    background-repeat: no-repeat;
    outline: none;
  }

  .blub2{
    position: absolute;
    background-image: url(../../static/media/blub2.24ece69e.svg);
    width: 100%;
    height: 100%;
    background-size: fit;  
    background-position: center;
    background-repeat: no-repeat;
    outline: none;
  }

  @media only screen and (max-width: 600px) {
    #loader{
      
        width: 150px;
        height: 150px;
      }
  }
