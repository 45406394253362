@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,400;0,600;0,900;1,300;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@700&display=swap');

.App {
  text-align: left;
}

body {
  background-color: white;
}

main {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
}


.container {
  max-height: 100vh;
  overflow-y: scroll;
}

.panel h1 {
  position: absolute;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-size: 15vw;
  line-height: 12vw;
  /* white-space: nowrap; */
}

.panel {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.galleryContainer {
  position: absolute;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
}

.galleryImage {
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}