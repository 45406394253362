#loader{
    position: relative;
    align-self: start;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 15vw;
    height: 15vw;
  }

  .blub1{
    position: absolute;
    background-image: url('./../../images/blub1.svg');
    width: 100%;
    height: 100%;
    background-size: fit;  
    background-position: center;
    background-repeat: no-repeat;
    outline: none;
  }

  .blub2{
    position: absolute;
    background-image: url('./../../images/blub2.svg');
    width: 100%;
    height: 100%;
    background-size: fit;  
    background-position: center;
    background-repeat: no-repeat;
    outline: none;
  }

  @media only screen and (max-width: 600px) {
    #loader{
      
        width: 150px;
        height: 150px;
      }
  }