@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');
#root, html, body{
    width: 100%;
    height: 100%;
    background-color: #333333;
}

/* main {
    display: block;
    position: relative;
    max-width: 1024px;
    min-width: 680px;
    background-color: white;
    padding: 30px 100px;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    box-sizing: border-box;
} */
.cv-title{
    font-size: 50px;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
}

.cv-subtitle{
    margin-top: 45px;
    font-size: 24px;
    font-family: 'Consolas', sans-serif;
    font-weight: 300;
    color:#2EA2C7;
    background-color: #282827;
    display: inline-block;
    padding: 5px 10px;
    box-sizing: border-box;
}
.cv-line{
    border:none;
    border-top: 2px dotted gray;
    background-color: none;
    margin-top: -2px;
    margin-left: -25px;
    margin-right: -25px;

}



.cv-name{
    font-size: 36px;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    margin-top: -10px;
}